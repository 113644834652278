import React from "react"

import Layout from "../components/Layout"
import Grid from "../components/Grid"
import css from "./setup.module.scss"
import PlatformSetup from "../components/Sections/PlatformSetup"

const metadata = {
  title: "Set up ReferralCandy for any ecommerce store",
}

const SetupPage = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>
          Set up ReferralCandy for any ecommerce store
        </h1>
        <PlatformSetup />
      </Grid>
    </Layout>
  )
}

export default SetupPage
