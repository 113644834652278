import React from "react"
import css from "./index.module.scss"
import { Link } from "gatsby"

import Grid from "../../Grid"

import SHOPIFY_LOGO from "../../../assets/images/integration_logos/shopify@3x.png"
import WOOCOMMERECE_LOGO from "../../../assets/images/integration_logos/woocommerce@3x.png"
import MAGENTO_LOGO from "../../../assets/images/integration_logos/magento@3x.png"
import BIGCOMMERCE_LOGO from "../../../assets/images/integration_logos/bigcommerce@3x.png"
import YAHOO_LOGO from "../../../assets/images/integration_logos/yahoo@3x.png"
import CELERY_LOGO from "../../../assets/images/integration_logos/celery@3x.png"
import VOLUSION_LOGO from "../../../assets/images/integration_logos/volusion@3x.png"
import THREEDCART_LOGO from "../../../assets/images/integration_logos/3dcart@3x.png"
import STANDALONE_LOGO from "../../../assets/images/integration_logos/custom@3x.png"

export default () => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <ul className={css.items}>
          <li>
            <img
              src={SHOPIFY_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Shopify</h3>
              <div>
                <p>
                  <Link to="/shopify/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={WOOCOMMERECE_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>WooCommerce</h3>
              <div>
                <p>
                  <Link to="https://help.referralcandy.com/support/solutions/articles/47001056328-integration-using-referralcandy-on-your-woocommerce-store">
                    View setup guide
                  </Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={MAGENTO_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Magento</h3>
              <div>
                <p>
                  <Link to="https://help.referralcandy.com/support/solutions/articles/47001166447-integration-using-referralcandy-on-your-magento-2-store">
                    View setup guide
                  </Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={BIGCOMMERCE_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Bigcommerce</h3>
              <div>
                <p>
                  <Link to="/bigcommerce/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={YAHOO_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Yahoo</h3>
              <div>
                <p>
                  <Link to="/yahoo/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={CELERY_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Celery</h3>
              <div>
                <p>
                  <Link to="/celery/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={VOLUSION_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Volusion</h3>
              <div>
                <p>
                  <Link to="/volusion/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={THREEDCART_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>3dcart</h3>
              <div>
                <p>
                  <Link to="/3dcart/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
          <li>
            <img
              src={STANDALONE_LOGO}
              alt="Referralcandy pricing - referralcandy cost customer referral program software"
              height="300"
              width="300"
            />
            <div className={css.feature_header}>
              <h3 className={css.header}>Other platforms</h3>
              <div>
                <p>
                  <Link to="/otherplatforms/setup">View setup guide</Link>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Grid>
  )
}
